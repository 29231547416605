export class linkedInModel {

        clientId: any;
        clientSecret: any;
        code: any;
        redirect_uri: any;
        userId: any;
        orgId: any;
        accessToken: any;
        linkdinUserId: any;
        linkdinUserName: any;
        socialMediaKeyword: any;
        profileImage: any;
        linkedinPageId:any;
        text:any;
        personId:any;
        brandId:any;
        userUid:any;
        title:any;
        posttitle:any;
        postOnSocialMediaUid:any;
        postOnSocialMedia:any;
}